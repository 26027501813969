import { Book2Icon } from '@/components/icons/Book2Icon';
import { LearningExprienceItem } from './learning-experience-item';
import { Subtitle } from './subtitle';
import { useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { dataConfig, useConfig } from '@/hooks/useConfig';
export const LearningExperience = ({
  coach,
  company,
  currentCourse,
  currentModule,
  dayToLearn,
  gatewayOptions,
  totalHours,
}: // changeDaysAndHours,
{
  currentCourse: string;
  currentModule: string;
  dayToLearn: string;
  gatewayOptions: string;
  totalHours: string;
  coach: string;
  company: string;
  changeDaysAndHours: string;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const config = useConfig();
  const { data: configData } = config as dataConfig;
  return (
    <section>
      <Subtitle
        icon={
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.4"
              d="M12 5.80001V21.83C12.17 21.83 12.35 21.8 12.49 21.72L12.53 21.7C14.45 20.65 17.8 19.55 19.97 19.26L20.26 19.22C21.22 19.1 22 18.2 22 17.24V5.16C22 3.97 21.03 3.07001 19.84 3.17001C17.74 3.34001 14.56 4.40003 12.78 5.51003L12.53 5.66C12.38 5.75 12.19 5.80001 12 5.80001Z"
              fill={theme.palette.primary.main}
            />
            <path
              d="M2 5.16996V17.24C2 18.2 2.77999 19.0999 3.73999 19.2199L4.07001 19.26C6.25001 19.55 9.61003 20.6599 11.53 21.7199C11.66 21.7999 11.82 21.83 12 21.83V5.79996C11.81 5.79996 11.62 5.74995 11.47 5.65995L11.3 5.54996C9.51999 4.42996 6.32998 3.35997 4.22998 3.17997H4.16998C2.97998 3.07997 2 3.96996 2 5.16996Z"
              fill={theme.palette.primary.main}
            />
            <path
              d="M19 3.28003V7.57001C19 7.97001 18.56 8.20999 18.22 7.98999L17 7.17999L15.78 7.98999C15.45 8.20999 15 7.97001 15 7.57001V4.41998C16.31 3.89998 17.77 3.48003 19 3.28003Z"
              fill={theme.palette.primary.main}
            />
          </svg>
        }
        text={
          configData?.custom_profile_sections?.student_profile_learning
            ? t('profileScreen.learningExperienceShort') +
              ` ${configData.custom_profile_sections.student_profile_learning}`
            : t('profileScreen.learningExperience')
        }
      />
      <LearningExprienceItem
        onClick={() => navigate('/change-module')}
        title={
          configData?.course_label
            ? configData.course_label
            : t(`profileScreen.currentCourse${config.isMeidei}`)
        }
        currentCourse={currentCourse}
        icon={
          <>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.91699 15.625C2.57533 15.625 2.29199 15.3417 2.29199 15V5.83335C2.29199 2.15835 3.40866 1.04169 7.08366 1.04169H12.917C16.592 1.04169 17.7087 2.15835 17.7087 5.83335V14.1667C17.7087 14.3 17.7087 14.425 17.7003 14.5584C17.6753 14.9 17.367 15.1667 17.0337 15.1417C16.692 15.1167 16.4253 14.8167 16.4503 14.475C16.4587 14.375 16.4587 14.2667 16.4587 14.1667V5.83335C16.4587 2.85835 15.9003 2.29169 12.917 2.29169H7.08366C4.10033 2.29169 3.54199 2.85835 3.54199 5.83335V15C3.54199 15.3417 3.25866 15.625 2.91699 15.625Z"
                fill="#352B47"
              />
              <path
                d="M14.167 18.9583H5.83366C3.88366 18.9583 2.29199 17.3667 2.29199 15.4167V14.875C2.29199 13.2167 3.64199 11.875 5.29199 11.875H17.0837C17.4253 11.875 17.7087 12.1583 17.7087 12.5V15.4167C17.7087 17.3667 16.117 18.9583 14.167 18.9583ZM5.29199 13.125C4.32533 13.125 3.54199 13.9083 3.54199 14.875V15.4167C3.54199 16.6833 4.56699 17.7083 5.83366 17.7083H14.167C15.4337 17.7083 16.4587 16.6833 16.4587 15.4167V13.125H5.29199Z"
                fill="#352B47"
              />
              <path
                d="M13.3337 6.45831H6.66699C6.32533 6.45831 6.04199 6.17498 6.04199 5.83331C6.04199 5.49165 6.32533 5.20831 6.66699 5.20831H13.3337C13.6753 5.20831 13.9587 5.49165 13.9587 5.83331C13.9587 6.17498 13.6753 6.45831 13.3337 6.45831Z"
                fill="#352B47"
              />
              <path
                d="M10.8337 9.375H6.66699C6.32533 9.375 6.04199 9.09167 6.04199 8.75C6.04199 8.40833 6.32533 8.125 6.66699 8.125H10.8337C11.1753 8.125 11.4587 8.40833 11.4587 8.75C11.4587 9.09167 11.1753 9.375 10.8337 9.375Z"
                fill="#352B47"
              />
            </svg>
          </>
        }
      />
      <LearningExprienceItem
        onClick={() => navigate('/change-module')}
        title={
          config.data?.module_label
            ? config.data.module_label
            : t(`profileScreen.currentSkill${config.isMeidei}`)
        }
        currentCourse={currentModule}
        icon={<Book2Icon />}
      />
      <LearningExprienceItem
        onClick={() => navigate('/profile/change-day-and-time')}
        title={t('profileScreen.daysAndPreferredHours')}
        currentCourse={dayToLearn}
        icon={
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.66699 4.79166C6.32533 4.79166 6.04199 4.50832 6.04199 4.16666V1.66666C6.04199 1.32499 6.32533 1.04166 6.66699 1.04166C7.00866 1.04166 7.29199 1.32499 7.29199 1.66666V4.16666C7.29199 4.50832 7.00866 4.79166 6.66699 4.79166Z"
              fill="#352B47"
            />
            <path
              d="M13.333 4.79166C12.9913 4.79166 12.708 4.50832 12.708 4.16666V1.66666C12.708 1.32499 12.9913 1.04166 13.333 1.04166C13.6747 1.04166 13.958 1.32499 13.958 1.66666V4.16666C13.958 4.50832 13.6747 4.79166 13.333 4.79166Z"
              fill="#352B47"
            />
            <path
              d="M17.0837 8.19998H2.91699C2.57533 8.19998 2.29199 7.91665 2.29199 7.57498C2.29199 7.23331 2.57533 6.94998 2.91699 6.94998H17.0837C17.4253 6.94998 17.7087 7.23331 17.7087 7.57498C17.7087 7.91665 17.4253 8.19998 17.0837 8.19998Z"
              fill="#352B47"
            />
            <path
              d="M13.3333 18.9583H6.66667C3.625 18.9583 1.875 17.2083 1.875 14.1667V7.08332C1.875 4.04166 3.625 2.29166 6.66667 2.29166H13.3333C16.375 2.29166 18.125 4.04166 18.125 7.08332V14.1667C18.125 17.2083 16.375 18.9583 13.3333 18.9583ZM6.66667 3.54166C4.28333 3.54166 3.125 4.69999 3.125 7.08332V14.1667C3.125 16.55 4.28333 17.7083 6.66667 17.7083H13.3333C15.7167 17.7083 16.875 16.55 16.875 14.1667V7.08332C16.875 4.69999 15.7167 3.54166 13.3333 3.54166H6.66667Z"
              fill="#352B47"
            />
            <path
              d="M7.08333 12.0833C6.975 12.0833 6.86666 12.0583 6.76666 12.0167C6.66666 11.975 6.57501 11.9167 6.49167 11.8417C6.41667 11.7583 6.35832 11.6667 6.31666 11.5667C6.27499 11.4667 6.25 11.3583 6.25 11.25C6.25 11.0333 6.34167 10.8167 6.49167 10.6583C6.57501 10.5833 6.66666 10.525 6.76666 10.4833C6.91666 10.4167 7.08334 10.4 7.25001 10.4333C7.30001 10.4417 7.35 10.4583 7.4 10.4833C7.45 10.5 7.5 10.525 7.55 10.5583C7.59166 10.5917 7.63333 10.625 7.67499 10.6583C7.70833 10.7 7.74999 10.7417 7.77499 10.7833C7.80832 10.8333 7.83334 10.8833 7.85001 10.9333C7.87501 10.9833 7.89168 11.0333 7.90001 11.0833C7.90834 11.1417 7.91667 11.1917 7.91667 11.25C7.91667 11.4667 7.82499 11.6833 7.67499 11.8417C7.51666 11.9917 7.3 12.0833 7.08333 12.0833Z"
              fill="#1F1333"
            />
            <path
              d="M10.0003 12.0833C9.78366 12.0833 9.567 11.9916 9.40867 11.8416C9.37533 11.8 9.342 11.7583 9.30867 11.7167C9.27534 11.6667 9.25031 11.6167 9.23365 11.5667C9.20865 11.5167 9.19198 11.4667 9.18365 11.4167C9.17532 11.3583 9.16699 11.3083 9.16699 11.25C9.16699 11.1417 9.19198 11.0333 9.23365 10.9333C9.27531 10.8333 9.33367 10.7417 9.40867 10.6583C9.642 10.425 10.017 10.35 10.317 10.4833C10.4253 10.525 10.5087 10.5833 10.592 10.6583C10.742 10.8167 10.8337 11.0333 10.8337 11.25C10.8337 11.3083 10.8253 11.3583 10.817 11.4167C10.8087 11.4667 10.792 11.5167 10.767 11.5667C10.7503 11.6167 10.7253 11.6667 10.692 11.7167C10.6586 11.7583 10.6253 11.8 10.592 11.8416C10.5087 11.9166 10.4253 11.975 10.317 12.0167C10.217 12.0583 10.1087 12.0833 10.0003 12.0833Z"
              fill="#1F1333"
            />
            <path
              d="M7.08333 15C6.975 15 6.86666 14.975 6.76666 14.9334C6.66666 14.8917 6.57501 14.8333 6.49167 14.7583C6.41667 14.675 6.35832 14.5917 6.31666 14.4833C6.27499 14.3833 6.25 14.275 6.25 14.1667C6.25 13.95 6.34167 13.7333 6.49167 13.575C6.57501 13.5 6.66666 13.4417 6.76666 13.4C7.075 13.2667 7.44166 13.3417 7.67499 13.575C7.70833 13.6167 7.74999 13.6583 7.77499 13.7C7.80832 13.75 7.83334 13.8 7.85001 13.85C7.87501 13.9 7.89168 13.95 7.90001 14.0083C7.90834 14.0583 7.91667 14.1167 7.91667 14.1667C7.91667 14.3833 7.82499 14.6 7.67499 14.7583C7.51666 14.9083 7.3 15 7.08333 15Z"
              fill="#1F1333"
            />
          </svg>
        }
      />
      <LearningExprienceItem
        onClick={() => navigate('/profile/gateway')}
        title={
          Boolean(configData?.custom_profile_sections?.student_profile_capsule)
            ? `${t('profileScreen.choiseGatewayShort')} ${
                configData.custom_profile_sections.student_profile_capsule
              }`
            : t(`profileScreen.choiseGateway${config.isMeidei}`)
        }
        currentCourse={gatewayOptions}
        icon={
          <>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.0003 19.0083C9.42533 19.0083 8.88366 18.7167 8.50033 18.2083L7.25033 16.5417C7.22533 16.5083 7.12533 16.4667 7.08366 16.4583H6.66699C3.19199 16.4583 1.04199 15.5167 1.04199 10.8333V6.66666C1.04199 2.98332 2.98366 1.04166 6.66699 1.04166H13.3337C17.017 1.04166 18.9587 2.98332 18.9587 6.66666V10.8333C18.9587 14.5167 17.017 16.4583 13.3337 16.4583H12.917C12.8503 16.4583 12.792 16.4917 12.7503 16.5417L11.5003 18.2083C11.117 18.7167 10.5753 19.0083 10.0003 19.0083ZM6.66699 2.29166C3.68366 2.29166 2.29199 3.68332 2.29199 6.66666V10.8333C2.29199 14.6 3.58366 15.2083 6.66699 15.2083H7.08366C7.50866 15.2083 7.99199 15.45 8.25033 15.7917L9.50033 17.4583C9.79199 17.8417 10.2087 17.8417 10.5003 17.4583L11.7503 15.7917C12.0253 15.425 12.4587 15.2083 12.917 15.2083H13.3337C16.317 15.2083 17.7087 13.8167 17.7087 10.8333V6.66666C17.7087 3.68332 16.317 2.29166 13.3337 2.29166H6.66699Z"
                fill="#352B47"
              />
              <path
                d="M10.0003 10C9.53366 10 9.16699 9.62501 9.16699 9.16668C9.16699 8.70834 9.54199 8.33334 10.0003 8.33334C10.4587 8.33334 10.8337 8.70834 10.8337 9.16668C10.8337 9.62501 10.467 10 10.0003 10Z"
                fill="#352B47"
              />
              <path
                d="M13.3333 10C12.8667 10 12.5 9.62501 12.5 9.16668C12.5 8.70834 12.875 8.33334 13.3333 8.33334C13.7917 8.33334 14.1667 8.70834 14.1667 9.16668C14.1667 9.62501 13.8 10 13.3333 10Z"
                fill="#352B47"
              />
              <path
                d="M6.66634 10C6.19967 10 5.83301 9.62501 5.83301 9.16668C5.83301 8.70834 6.20801 8.33334 6.66634 8.33334C7.12467 8.33334 7.49967 8.70834 7.49967 9.16668C7.49967 9.62501 7.13301 10 6.66634 10Z"
                fill="#352B47"
              />
            </svg>
          </>
        }
      />
      <LearningExprienceItem
        showIcon={false}
        opacity
        title={
          configData?.custom_profile_sections?.consumed_hours_profile_capsule
            ? configData.custom_profile_sections.consumed_hours_profile_capsule
            : t('profileScreen.consumedLearningHours')
        }
        currentCourse={totalHours}
        icon={
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.0003 18.9583C5.05866 18.9583 1.04199 14.9417 1.04199 10C1.04199 5.05834 5.05866 1.04167 10.0003 1.04167C14.942 1.04167 18.9587 5.05834 18.9587 10C18.9587 14.9417 14.942 18.9583 10.0003 18.9583ZM10.0003 2.29167C5.75033 2.29167 2.29199 5.75001 2.29199 10C2.29199 14.25 5.75033 17.7083 10.0003 17.7083C14.2503 17.7083 17.7087 14.25 17.7087 10C17.7087 5.75001 14.2503 2.29167 10.0003 2.29167Z"
              fill="#352B47"
            />
            <path
              d="M13.0919 13.275C12.9835 13.275 12.8752 13.25 12.7752 13.1833L10.1919 11.6417C9.55019 11.2583 9.0752 10.4167 9.0752 9.675V6.25833C9.0752 5.91666 9.35853 5.63333 9.7002 5.63333C10.0419 5.63333 10.3252 5.91666 10.3252 6.25833V9.675C10.3252 9.975 10.5752 10.4167 10.8335 10.5667L13.4169 12.1083C13.7169 12.2833 13.8085 12.6667 13.6335 12.9667C13.5085 13.1667 13.3002 13.275 13.0919 13.275Z"
              fill="#352B47"
            />
          </svg>
        }
      />
      <LearningExprienceItem
        showIcon={false}
        opacity
        title={
          configData?.custom_profile_sections?.coach_profile_capsule
            ? configData.custom_profile_sections.coach_profile_capsule
            : t('profileScreen.coach')
        }
        currentCourse={coach}
        icon={
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.9998 18.9583C9.44147 18.9583 8.8748 18.8166 8.3748 18.525L3.4248 15.6666C2.4248 15.0833 1.7998 14.0083 1.7998 12.85V7.15C1.7998 5.99166 2.4248 4.91666 3.4248 4.33333L8.3748 1.475C9.3748 0.891665 10.6165 0.891665 11.6248 1.475L16.5748 4.33333C17.5748 4.91666 18.1998 5.99166 18.1998 7.15V12.85C18.1998 14.0083 17.5748 15.0833 16.5748 15.6666L11.6248 18.525C11.1248 18.8166 10.5581 18.9583 9.9998 18.9583ZM9.9998 2.29165C9.65814 2.29165 9.30814 2.38332 8.9998 2.55832L4.0498 5.41665C3.43314 5.77498 3.0498 6.43333 3.0498 7.15V12.85C3.0498 13.5583 3.43314 14.225 4.0498 14.5833L8.9998 17.4416C9.61647 17.8 10.3831 17.8 10.9998 17.4416L15.9498 14.5833C16.5665 14.225 16.9498 13.5666 16.9498 12.85V7.15C16.9498 6.44166 16.5665 5.77498 15.9498 5.41665L10.9998 2.55832C10.6915 2.38332 10.3415 2.29165 9.9998 2.29165Z"
              fill="#352B47"
            />
            <path
              d="M10.0003 9.79172C8.58359 9.79172 7.43359 8.64171 7.43359 7.22504C7.43359 5.80837 8.58359 4.65841 10.0003 4.65841C11.4169 4.65841 12.5669 5.80837 12.5669 7.22504C12.5669 8.64171 11.4169 9.79172 10.0003 9.79172ZM10.0003 5.90841C9.27526 5.90841 8.68359 6.50004 8.68359 7.22504C8.68359 7.95004 9.27526 8.54172 10.0003 8.54172C10.7253 8.54172 11.3169 7.95004 11.3169 7.22504C11.3169 6.50004 10.7253 5.90841 10.0003 5.90841Z"
              fill="#352B47"
            />
            <path
              d="M13.3337 14.5084C12.992 14.5084 12.7087 14.2251 12.7087 13.8834C12.7087 12.7334 11.492 11.7918 10.0003 11.7918C8.50866 11.7918 7.29199 12.7334 7.29199 13.8834C7.29199 14.2251 7.00866 14.5084 6.66699 14.5084C6.32533 14.5084 6.04199 14.2251 6.04199 13.8834C6.04199 12.0417 7.81699 10.5418 10.0003 10.5418C12.1837 10.5418 13.9587 12.0417 13.9587 13.8834C13.9587 14.2251 13.6753 14.5084 13.3337 14.5084Z"
              fill="#352B47"
            />
          </svg>
        }
      />
      <LearningExprienceItem
        showIcon={false}
        opacity
        title={
          configData?.custom_profile_sections?.company_profile_capsule
            ? configData.custom_profile_sections.company_profile_capsule
            : t(`general.company${config.isMeidei}`)
        }
        currentCourse={company}
        icon={
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.3337 18.9583H1.66699C1.32533 18.9583 1.04199 18.675 1.04199 18.3333C1.04199 17.9917 1.32533 17.7083 1.66699 17.7083H18.3337C18.6753 17.7083 18.9587 17.9917 18.9587 18.3333C18.9587 18.675 18.6753 18.9583 18.3337 18.9583Z"
              fill="#352B47"
            />
            <path
              d="M17.5 18.9583H2.5C2.15833 18.9583 1.875 18.675 1.875 18.3333V5C1.875 2.48333 3.31667 1.04167 5.83333 1.04167H14.1667C16.6833 1.04167 18.125 2.48333 18.125 5V18.3333C18.125 18.675 17.8417 18.9583 17.5 18.9583ZM3.125 17.7083H16.875V5C16.875 3.15 16.0167 2.29167 14.1667 2.29167H5.83333C3.98333 2.29167 3.125 3.15 3.125 5V17.7083Z"
              fill="#352B47"
            />
            <path
              d="M8.33301 14.375H5.83301C5.49134 14.375 5.20801 14.0917 5.20801 13.75C5.20801 13.4083 5.49134 13.125 5.83301 13.125H8.33301C8.67467 13.125 8.95801 13.4083 8.95801 13.75C8.95801 14.0917 8.67467 14.375 8.33301 14.375Z"
              fill="#352B47"
            />
            <path
              d="M14.167 14.375H11.667C11.3253 14.375 11.042 14.0917 11.042 13.75C11.042 13.4083 11.3253 13.125 11.667 13.125H14.167C14.5087 13.125 14.792 13.4083 14.792 13.75C14.792 14.0917 14.5087 14.375 14.167 14.375Z"
              fill="#1F1333"
            />
            <path
              d="M8.33301 10.625H5.83301C5.49134 10.625 5.20801 10.3417 5.20801 10C5.20801 9.65833 5.49134 9.375 5.83301 9.375H8.33301C8.67467 9.375 8.95801 9.65833 8.95801 10C8.95801 10.3417 8.67467 10.625 8.33301 10.625Z"
              fill="#1F1333"
            />
            <path
              d="M14.167 10.625H11.667C11.3253 10.625 11.042 10.3417 11.042 10C11.042 9.65833 11.3253 9.375 11.667 9.375H14.167C14.5087 9.375 14.792 9.65833 14.792 10C14.792 10.3417 14.5087 10.625 14.167 10.625Z"
              fill="#1F1333"
            />
            <path
              d="M8.33301 6.875H5.83301C5.49134 6.875 5.20801 6.59167 5.20801 6.25C5.20801 5.90833 5.49134 5.625 5.83301 5.625H8.33301C8.67467 5.625 8.95801 5.90833 8.95801 6.25C8.95801 6.59167 8.67467 6.875 8.33301 6.875Z"
              fill="#1F1333"
            />
            <path
              d="M14.167 6.875H11.667C11.3253 6.875 11.042 6.59167 11.042 6.25C11.042 5.90833 11.3253 5.625 11.667 5.625H14.167C14.5087 5.625 14.792 5.90833 14.792 6.25C14.792 6.59167 14.5087 6.875 14.167 6.875Z"
              fill="#1F1333"
            />
          </svg>
        }
      />
    </section>
  );
};
