import { useNavigate } from 'react-router-dom';
import { Subtitle } from './subtitle';
import { Input } from '@/components/@core/input';
import { useFormik } from 'formik';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { MenuItem, Select, useTheme } from '@mui/material';
import { hexToRgbaWithOpacity } from '../../../../utils/hexToRgbaWithOpacity';
import { useProfilePage } from '@/pages/v2/profile/hooks/useProfilePage';
import { useDocumentType } from '@/pages/v2/profile/hooks/useDocumentType';
import CustomDatePicker from '@/components/DatePicker/DatePicker';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { patch } from '@/services/api';
import { useEffect, useState } from 'react';
import { parseDate } from '@/selectors/date';
import CustomAlert from '@/components/Alert/Alert';
import { useTranslation } from 'react-i18next';
import { QueryKeys } from '@/constants/queryKeys';
import { ExtraFieldsInputs, getExtraFieldsObject } from './extra-fields-inputs';
import { useConfig } from '@/hooks/useConfig';

export const PersonalInformation = () => {
  const config = useConfig();
  const navigate = useNavigate();
  const profile = useProfilePage(false);
  const document_types = useDocumentType();
  const theme = useTheme();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [isEditing] = useState(true);
  const [showAlert, setShowAlert] = useState(false);

  const Schema = z.object({
    first_name: z.string({
      message: `${t('general.fieldRequired')}`,
    }),
    last_name: z.string({
      message: `${t('general.fieldRequired')}`,
    }),
    document_type: z.string({
      message: `${t('general.fieldRequired')}`,
    }),
    document_number: z.string({
      message: `${t('general.fieldRequired')}`,
    }),
    friendly_name: z.string({
      message: `${t('general.fieldRequired')}`,
    }),
    email: z
      .string({
        message: `${t('general.fieldRequired')}`,
      })
      .email({
        message: `${t('general.isNotValidEmail')}`,
      }),
  });

  const formik = useFormik({
    initialValues: {
      first_name: profile.data?.first_name ?? '',
      last_name: profile.data?.last_name ?? '',
      friendly_name: profile.data?.profile.friendly_name ?? '',
      email: profile.data?.email ?? '',
      phone_number: profile.data?.phone_number ?? '',
      document_number: profile.data?.profile.document_number,
      document_type: profile.data?.profile.document_type ?? '',
      birthdate: profile.data?.profile.birthdate ?? '',
      // TODO extra fields
      extra_fields: {
        ...getExtraFieldsObject(
          profile.data?.profile.company_extra_fields ?? [],
          profile.data?.profile.extra_fields ?? {},
        ),
      },
    },
    onSubmit: () => {
      mutation.mutate();
      queryClient.invalidateQueries({ queryKey: [QueryKeys.PROFILE] });
    },
    validationSchema: toFormikValidationSchema(Schema),
    enableReinitialize: true,
  });

  const getValidDocumentTypeValue = (value: string) => {
    const WEIRD_VALUES = [
      'cédula de ciudadanía',
      'tarjeta de identidad',
      'cédula de extranjería',
      'dni',
      'ine',
      'pasaporte',
      'permiso especial',
      'salvo conducto de permanencia',
      'tarjeta de identidad',
    ];
    const ACCEPT_VALUE = {
      'cédula de ciudadanía': 'CEDULA',
      'cédula de extranjería': 'CEDULA_EX',
      dni: 'DNI',
      ine: 'INE',
      pasaporte: 'PASS',
      'permiso especial': 'PERMISO',
      'salvo conducto de permanencia': 'SALVOC',
      'tarjeta de identidad': 'TI',
    };

    const document_type = WEIRD_VALUES.includes(value.toLocaleLowerCase())
      ? // @ts-ignore
        ACCEPT_VALUE[value.toLocaleLowerCase()]
      : value;

    return document_type;
  };

  const mutation = useMutation({
    mutationFn: async () => {
      let birthdate = formik.values.birthdate ?? '';
      birthdate = parseDate(formik.values.birthdate);

      const { data } = await patch(`users/:user_pk/`, {
        first_name: formik.values.first_name,
        last_name: formik.values.last_name,
        friendly_name: formik.values.friendly_name,
        email: formik.values.email,
        document_number: formik.values.document_number,
        document_type: getValidDocumentTypeValue(formik.values.document_type),
        birthdate: birthdate,
        extra_fields: formik.values.extra_fields,
      });
      return data;
    },
    onSuccess: () => {
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    },
  });
  const onCancel = () => {
    navigate('/');
  };

  const withOpacity = hexToRgbaWithOpacity(theme.palette.primary.main, 0.6);

  const [showExtraFields, setShowExtraFields] = useState(false);
  useEffect(() => {
    if (profile.isSuccess) {
      setTimeout(() => {
        setShowExtraFields(true);
      }, 2000);
    }
  }, [formik.values.extra_fields, profile.isSuccess]);

  return (
    <section>
      <Subtitle
        icon={
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 13.5C17.06 13.5 16.19 13.83 15.5 14.38C14.58 15.11 14 16.24 14 17.5C14 18.25 14.21 18.96 14.58 19.56C15.27 20.72 16.54 21.5 18 21.5C19.01 21.5 19.93 21.13 20.63 20.5C20.94 20.24 21.21 19.92 21.42 19.56C21.79 18.96 22 18.25 22 17.5C22 15.29 20.21 13.5 18 13.5ZM20.07 17.07L17.94 19.04C17.8 19.17 17.61 19.24 17.43 19.24C17.24 19.24 17.05 19.17 16.9 19.02L15.91 18.03C15.62 17.74 15.62 17.26 15.91 16.97C16.2 16.68 16.68 16.68 16.97 16.97L17.45 17.45L19.05 15.97C19.35 15.69 19.83 15.71 20.11 16.01C20.39 16.31 20.37 16.78 20.07 17.07Z"
              fill={theme.palette.primary.main}
            />
            <path
              opacity="0.4"
              d="M21.0901 22C21.0901 22.28 20.8701 22.5 20.5901 22.5H3.41016C3.13016 22.5 2.91016 22.28 2.91016 22C2.91016 17.86 6.99015 14.5 12.0002 14.5C13.0302 14.5 14.0302 14.64 14.9502 14.91C14.3602 15.61 14.0002 16.52 14.0002 17.5C14.0002 18.25 14.2101 18.96 14.5801 19.56C14.7801 19.9 15.0401 20.21 15.3401 20.47C16.0401 21.11 16.9702 21.5 18.0002 21.5C19.1202 21.5 20.1302 21.04 20.8502 20.3C21.0102 20.84 21.0901 21.41 21.0901 22Z"
              fill={theme.palette.primary.main}
            />
            <path
              d="M12 12.5C14.7614 12.5 17 10.2614 17 7.5C17 4.73858 14.7614 2.5 12 2.5C9.23858 2.5 7 4.73858 7 7.5C7 10.2614 9.23858 12.5 12 12.5Z"
              fill={theme.palette.primary.main}
            />
          </svg>
        }
        text={t('profileScreen.personalInformation')}
      />
      <form
        onSubmit={formik.handleSubmit}
        style={{
          position: 'relative',
        }}
      >
        <Input
          disabled={config.restrict_name_certificate}
          style={{
            marginBottom: '1rem',
          }}
          label={t('profileScreen.name')}
          inputProps={{
            type: 'text',
            ...formik.getFieldProps('first_name'),
          }}
          isError={!!formik.errors.first_name && formik.touched.first_name}
          errorText={formik.errors.first_name}
        />
        <Input
          disabled={config.restrict_name_certificate}
          style={{
            marginBottom: '1rem',
          }}
          label={t('profileScreen.lastname')}
          inputProps={{
            type: 'text',
            ...formik.getFieldProps('last_name'),
          }}
          isError={!!formik.errors.last_name && formik.touched.last_name}
          errorText={formik.errors.last_name}
        />
        <Input
          style={{
            marginBottom: '1rem',
          }}
          label={t('profileScreen.friendlyName')}
          inputProps={{
            type: 'text',
            ...formik.getFieldProps('friendly_name'),
          }}
          isError={
            !!formik.errors.friendly_name && formik.touched.friendly_name
          }
          errorText={formik.errors.friendly_name}
        />
        <Input
          style={{
            marginBottom: '1rem',
          }}
          label={t('profileScreen.emailAddress')}
          inputProps={{
            type: 'text',
            ...formik.getFieldProps('email'),
          }}
          isError={!!formik.errors.email && formik.touched.email}
          errorText={formik.errors.email}
        />
        <Input
          style={{
            marginBottom: '1rem',
          }}
          label={t('profileScreen.phoneNumber')}
          inputProps={{
            disabled: true,
            type: 'text',
            ...formik.getFieldProps('phone_number'),
          }}
        />
        <div style={{ marginBottom: '1rem' }}>
          <label
            htmlFor=""
            style={{
              fontSize: '12px',
              color: '#625A70',
            }}
          >
            {t('profileScreen.dateBirth')}
          </label>
          <CustomDatePicker
            value={formik.values.birthdate}
            name="birthdate"
            onChange={formik.setFieldValue}
            format="dd/MM/yyyy"
          />
        </div>

        <label
          htmlFor=""
          style={{
            fontSize: '12px',
            color: '#625A70',
          }}
        >
          {t('profileScreen.documentType')}
        </label>
        <Select
          {...formik.getFieldProps('document_type')}
          value={
            document_types.isSuccess && document_types?.data
              ? formik.getFieldProps('document_type').value
              : ''
          }
          displayEmpty
          inputProps={{
            'aria-label': 'Without label',
          }}
          sx={{
            width: '100%',
            marginBottom: '1rem',
            paddingRight: '.8rem',
          }}
          // IconComponent={() => (
          //   <svg
          //     width="16"
          //     height="16"
          //     viewBox="0 0 16 16"
          //     fill="none"
          //     xmlns="http://www.w3.org/2000/svg"
          //   >
          //     <path
          //       d="M8.00001 11.2C7.53335 11.2 7.06668 11.02 6.71335 10.6667L2.36668 6.31999C2.17335 6.12666 2.17335 5.80666 2.36668 5.61332C2.56001 5.41999 2.88001 5.41999 3.07335 5.61332L7.42001 9.95999C7.74001 10.28 8.26001 10.28 8.58001 9.95999L12.9267 5.61332C13.12 5.41999 13.44 5.41999 13.6333 5.61332C13.8267 5.80666 13.8267 6.12666 13.6333 6.31999L9.28668 10.6667C8.93335 11.02 8.46668 11.2 8.00001 11.2Z"
          //       fill="#797185"
          //     />
          //   </svg>
          // )}

          error={!!formik.errors.document_type && formik.touched.document_type}
        >
          <MenuItem disabled value="">
            {t('general.selectOption')}
          </MenuItem>
          {document_types.isSuccess &&
            document_types?.data &&
            Object.entries(document_types?.data).map(([key, value]) => (
              <MenuItem key={key} value={value}>
                {/* @ts-ignore */}
                {value}
              </MenuItem>
            ))}
        </Select>
        <Input
          style={{
            marginBottom: '1rem',
          }}
          label={t('profileScreen.documentNumber')}
          inputProps={{
            type: 'text',
            ...formik.getFieldProps('document_number'),
          }}
          isError={
            !!formik.errors.document_number && formik.touched.document_number
          }
          errorText={formik.errors.document_number}
        />

        {showExtraFields && (
          <ExtraFieldsInputs
            extra_fields={profile.data?.profile.company_extra_fields ?? []}
            getFieldProps={formik.getFieldProps}
          />
        )}

        {showAlert && (
          <div
            style={{
              marginBottom: '1rem',
            }}
          >
            <CustomAlert
              message={t('general.messageAfterSave')}
              severity={'success'}
              onClose={() => setShowAlert(false)}
            />
          </div>
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            // position: 'absolute',
            width: '100%',
            gap: '1rem',
          }}
        >
          <button
            disabled={mutation.isPending}
            style={{
              width: '100%',
              height: '44px',
              textAlign: 'center',
              borderRadius: '32px',
              background: isEditing ? theme.palette.primary.main : withOpacity,
              color: 'white',
              border: 'none',
              cursor: 'pointer',
            }}
            type="submit"
          >
            {mutation.isPending
              ? t('general.saving')
              : t('general.saveChanges')}
          </button>
          <button
            style={{
              width: '100%',
              height: '44px',
              textAlign: 'center',
              borderRadius: '32px',
              border: '1.5px solid #684294',
              background: 'transparent',
              cursor: 'pointer',
            }}
            onClick={onCancel}
          >
            {t('general.cancel')}
          </button>
        </div>
      </form>
    </section>
  );
};
