import React, { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

// components
import { Navbar } from './components/Navbar';
import { Footer } from './components/Footer';
import PersonalizedTheme from './components/personalizedTheme';
import './App.css';
import { AlertProvider } from './context/AlertContext';
import { AnimatePresence } from 'framer-motion';
import { Alert } from './pages/Alert';
import { lazyRetry } from './utils/general';
import Spinner from './components/Spinner';
import { Toast } from './components/Toast';
import { AuthInterceptorComponent } from '@/components/AuthInterceptorComponent';
import { BottomNavbar } from '@/components/BottomNavbar';
import { Toaster } from 'react-hot-toast';
import Routes from '@/routes';
import ErrorBoundary from '@/common/errorBoundary';

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    shade: Palette['primary'];
    loaded: boolean;
  }
  interface PaletteOptions {
    shade?: PaletteOptions['primary'];
    loaded?: boolean;
  }
}

const NotificationSurvey = React.lazy(() =>
  lazyRetry(() => import('./pages/Notification/NotificationSurvey')),
);

function App() {
  return (
    <Router>
      <ErrorBoundary>
        <AuthInterceptorComponent>
          <PersonalizedTheme>
            <Suspense fallback={<Spinner />}>
              <div
                className="container"
                style={{
                  overflowY: 'auto',
                  paddingBottom: '5rem',
                }}
              >
                <Navbar />
                <Toast />
                <Toaster /> {/* React host toast library */}
                <BottomNavbar />
                <div className="content">
                  <Alert />
                  <NotificationSurvey />
                  <AnimatePresence mode="wait">
                    <Routes />
                  </AnimatePresence>
                </div>
                <div className="footer">
                  <Footer />
                </div>
              </div>
            </Suspense>
          </PersonalizedTheme>
        </AuthInterceptorComponent>
      </ErrorBoundary>
    </Router>
  );
}

function AlertContex(props: any) {
  return (
    <AlertProvider>
      <App {...props} />
    </AlertProvider>
  );
}

export default AlertContex;
