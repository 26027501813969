import { Box } from '@mui/material';
import { CustomThemeValues } from '../../constants/styles';

export const Card = ({
  children,
  style,
}: {
  children: React.ReactNode;
  style?: React.CSSProperties;
}) => {
  return (
    <Box
      style={style}
      sx={{
        border: CustomThemeValues.CARD,
        borderRadius: CustomThemeValues.BORDER,
        paddingY: '10px',
        paddingX: '14px',
      }}
    >
      {children}
    </Box>
  );
};
