import { Fragment, useState } from 'react';
import { Box, Divider, Typography, useTheme } from '@mui/material';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';

// Components
import ItemList, { Item } from './ItemList';
import InfoDrawer from './InfoDrawer';
import { PointsLogSkeleton } from './SkeletonPoints';

// Icons
import { BurgerIcon } from '../../../components/icons/BurgerIcon';

// Constants
import { TYPOGRAPHY } from '../../ListContentPage/constants';
import { apiUrls } from '../../../constants/apiUrls';
import { QueryKeys } from '../../../constants/queryKeys';

// Services
import { get } from '../../../services/api';

export default function List() {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme();

  const drawerToggle = () => {
    setOpen(!open);
  };

  const getPointsLogs = async (page: number) => {
    const offset = page * 50;
    return get(apiUrls.users.points_logger + `?limit=50&offset=${offset}`);
  };

  const { data, fetchNextPage, isLoading, isError, isSuccess, isFetching } =
    useInfiniteQuery({
      queryKey: [QueryKeys.POINTS_LOGS],
      queryFn: ({ pageParam }: { pageParam: number }) =>
        getPointsLogs(pageParam),
      initialPageParam: 0,
      getNextPageParam: (_lastpage, _allPages, lastPageParam) => {
        return lastPageParam + 1;
      },
    });

  const dataParser = (allPages: any) => {
    if (!allPages) {
      return [];
    }
    return allPages.map((page: any) => page?.data?.results).flat();
  };

  const hasMore = (allPages: any) => {
    if (!allPages.length) return false;
    return allPages[allPages.length - 1].data?.next !== null;
  };

  return (
    <>
      <InfoDrawer open={open} onClose={drawerToggle} />
      <section>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '2rem',
          }}
        >
          <Typography
            color={theme.palette.primary.main}
            fontFamily={TYPOGRAPHY.bold}
            display={'inline-block'}
          >
            {t('pointsScreen.historicalPoints')}
          </Typography>
          <span
            onClick={drawerToggle}
            style={{
              cursor: 'pointer',
            }}
          >
            <BurgerIcon />
          </span>
        </Box>
        <Box
          sx={{
            marginTop: '1rem',
          }}
        >
          {isLoading && <PointsLogSkeleton />}
          {isError && <Typography>{t('pointsScreen.error')}</Typography>}
          {!isLoading && (
            <InfiniteScroll
              dataLength={dataParser(data?.pages).length}
              next={fetchNextPage}
              hasMore={hasMore(data?.pages)}
              loader={undefined}
            >
              {!isLoading &&
                isSuccess &&
                dataParser(data.pages).map((item: Item, index: number) => {
                  return (
                    <Fragment key={item?.created}>
                      {index % 5 === 0 && <Divider />}
                      <ItemList {...item} />
                    </Fragment>
                  );
                })}
            </InfiniteScroll>
          )}
        </Box>
      </section>
    </>
  );
}
