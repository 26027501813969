import { SxProps } from '@mui/material';

export enum CustomThemeValues {
  BORDER = '.6rem',
  CARD = '1px solid #EDF0EB',
  CARD_IS_SELECTED = '1px solid #8F8999',
  OPACITY = 0.2,
}

export const appContainer: SxProps = {
  maxWidth: {
    sm: '100%',
    md: '70%',
  },
  marginX: 'auto',
};
