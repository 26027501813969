import {
  handleApiError,
  redirect,
} from '@/components/AuthInterceptorComponent/utils';
import { apiUrls } from '@/constants/apiUrls';
import { post } from '@/services/api';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export interface MagicCheckerResponse {
  user: User;
  access_token: string;
  needs_trusted_device: boolean;
}

export interface User {
  pk: number;
}

export const useLogin = () => {
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const removeTokenFromSearchParams = () => {
    searchParams.delete('token');
    navigate(
      {
        search: searchParams.toString(),
      },
      {
        replace: true,
      },
    );
  };

  const login = async (token: string) => {
    try {
      const { data } = await post(apiUrls.auth.login, {
        token: token,
      });
      if (data)
        window.localStorage.setItem('access_token', data?.access_token ?? '');
      if (data) window.localStorage.setItem('user_id', data?.user.pk ?? '');
      return data;
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    const hasToken =
      searchParams.get('token') || window.localStorage.getItem('access_token');
    if (hasToken) {
      login(hasToken)
        .then(() => {
          setIsSuccess(true);
          removeTokenFromSearchParams();
        })
        .catch(() => {
          setIsError(true);
          handleApiError();
        })
        .finally(() => {
          setIsLoading(false);
        });
      return;
    }
    if (!hasToken) {
      setIsLoading(false);
      redirect();
    }
  }, []);

  return {
    isLoading,
    isError,
    isSuccess,
    refetch: () =>
      login(
        searchParams.get('token') ||
          window.localStorage.getItem('access_token') ||
          '',
      ),
  };
};
